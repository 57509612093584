<template>
  <CCard>
    <CCardHeader @click="cardCollapse = !cardCollapse">
      <h5 class="card-title">{{ messages.home.purchases.toUpperCase() }}</h5>
    </CCardHeader>
    <CCollapse :show="cardCollapse">
      <CCardBody v-if="!invoiceNoCumulative.show">
        <div class="text-center">
          <Loader
            width="100px"
            height="100px"
            fill-color="#c2c2c2"
            cssClass="fadeIn anim-speed--1s"
          />
        </div>
      </CCardBody>
      <CCardBody v-if="invoiceNoCumulative.show">
        <CRow class="row-wrap fadeIn anim-speed--3s">
          <CCol xl="3" class="prevent-min-width">
            <div class="purchases-data">
              <div class="purchases-data--block">
                <p class="home--main-amount">{{ monthAmount }} €</p>
                <p>{{ messages.home.currentMonthPurchases }}</p>
              </div>
              <div class="purchases-data--block">
                <p class="home--secondary-amount">{{ cumulativeAmount }} €</p>
                <p>{{ messages.home.cumulativeTotal }} FY {{ selectedFY }}</p>
              </div>
              <div class="purchases-data--block">
                <CButton color="primary" :to="{ name: 'Cuadro de mando' }">{{
                  messages.home.details.toUpperCase()
                }}</CButton>
              </div>
            </div>
          </CCol>
          <CCol xl="9">
            <CChartBar
              class="invoice-table"
              :options="{ maintainAspectRatio: false }"
              :datasets="invoiceNoCumulative.datasets"
              :labels="invoiceNoCumulative.labels"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import formatNum from "@/utils/formatNum";

const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");

export default {
  name: "PurchasesCard",
  components: {
    Loader,
  },
  data() {
    return {
      user: null,
      lang: null,
      monthAmount: null,
      cumulativeAmount: null,
      selectedFY: null,
      invoiceNoCumulative: {
        show: false,
        datasets: [],
        labels: [],
      },
      messages: null,
      cardCollapse: true,
    };
  },
  computed: {
    // Refactor
    implicitParams() {
      const n = this.user.profileId;
      const params = {
        concessionCode: "",
        countryCode: "",
        regionalCode: "",
        aspm: "",
      };
      if (n >= 20 && n < 30) {
        params.concessionCode = this.user.concessionCode;
      } else if (n >= 10) {
        params.aspm = this.user.aspm;
      } else {
        params.countryCode = this.user.countryCode;
      }
      return params;
    },
  },
  methods: {
    defaultParams() {
      currentFY().then((currentFY) => {
        this.selectedFY = currentFY;
        const currentMonth = new Date().getMonth() + 1;
        // const currentMonth = new Date().getMonth();
        let params = {};
        params.area = { ...this.implicitParams };
        params.fy = { fiscalYear: currentFY };
        params.month = { month: currentMonth };
        const invoiceParams = {
          ...params.area,
          ...params.fy,
          ...params.month,
        };

        // Query to get invoice data
        this.getInvoiceData(invoiceParams, 0).then((results) => {
          this.showPurchasesAmount(results);
          this.showInvoiceTable(results);
        });
      });
    },
    // Get data for invoice tables
    getInvoiceData(params) {
      console.log("invoice params", params);
      return Api.sendPost("dashboard/invoice", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            return response.data.data;
          }
        }
      });
    },
    showPurchasesAmount(data) {
      this.monthAmount = formatNum.get(data.currentMonthPurchases, 0);
      this.cumulativeAmount = formatNum.get(data.totalPurchases, 0);
    },
    async getMonthsList() {
      const response = await Api.sendGet("sys/filter/months");
      return response.data.data;
    },
    // Refactor (Also used in Control Panel)
    showInvoiceTable(data) {
      const labelsAxisX = [];
      const lastFYData = {
        label: `FY ${this.selectedFY - 1}`,
        backgroundColor: "rgba(0,0,0,0.3)",
        data: [],
      };

      const currentFYData = {
        label: `FY ${this.selectedFY}`,
        backgroundColor: "rgba(195,0, 47, 0.6)",
        data: [],
      };

      this.getMonthsList().then((monthsList) => {
        const list = data.reporterInvoiceDtoList;
        list.map((el) => {
          labelsAxisX.push(this.messages.filters.months[el.month]);
          lastFYData.data.push(el.lastFyPurchases);
          currentFYData.data.push(el.currentFyPurchases);
        });

        if (list.length < 12) {
          for (let i = list.length; i < monthsList.length; i++) {
            let monthNumber = monthsList[i];
            labelsAxisX.push(this.messages.filters.months[monthNumber]);
            lastFYData.data.push(0);
            currentFYData.data.push(0);
          }
        }

        this.invoiceNoCumulative.datasets = [lastFYData, currentFYData];
        this.invoiceNoCumulative.labels = labelsAxisX;
        this.invoiceNoCumulative.show = true;
      });
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.defaultParams();
  },
};
</script>

<style lang="scss" scoped>
$row1Height: 220px;

.row-wrap {
  flex-wrap: wrap;
}

.prevent-min-width {
  min-width: 240px;
}
.purchases-data {
  min-width: 220px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding-bottom: 1.2rem;

  &--block {
    margin-right: 1.6rem;
  }

  &--month-amount {
    white-space: inherit;
    color: #c3002f;
    font-size: 2.7rem;
    margin-bottom: 0;
    line-height: 1;
  }

  &--cumulative-amount {
    white-space: inherit;
    font-size: 1.8rem;
    margin-bottom: 0;
    line-height: 1;
  }
}
.invoice-table {
  height: $row1Height;
}

@media screen and (min-width: 1200px) {
  .purchases-data {
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-between;
    height: $row1Height;
    padding: 0.8rem 0;
  }
}
</style>
